import React, { Component } from "react";
import Select from "react-select";

import { SERVICES, THEME_COLOR } from "../constants";

class SelectModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableModules: Array.from(
        new Set(SERVICES.map((service) => service.module))
      ).map((service) => ({
        value: service,
        label: service,
      })),
    };
  }

  render() {
    return (
      <>
        <Select
          className="select__api"
          autoFocus={this.props.autoFocus}
          value={this.props.value}
          onChange={this.props.onChange}
          options={this.state.availableModules}
          noOptionsMessage={() => "Nenhum módulo encontrado..."}
          placeholder={"Selecione um módulo..."}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: THEME_COLOR,
            },
          })}
        />
      </>
    );
  }
}

export default SelectModule;
