import React, { Component } from "react";
import slugify from "slugify";

import AppFooter from "../AppFooter/AppFooter";
import SelectApi from "../SelectApi/SelectApi";
import SelectModule from "../SelectModule/SelectModule";
import { SERVICES } from "../constants";

import "./SelectApiPage.css";
import Logo from "../logo-orbit.png";

class SelectApiPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedModule: undefined,
      availableApisForService: [],
    };
  }

  handleModuleChange = (selectedModule) => {
    this.setState({
      selectedModule: selectedModule.value,
      availableApisForService: SERVICES.filter(
        (item) => item.module === selectedModule.value
      ).map((item) => ({
        value: slugify(item.name).toLowerCase(),
        label: item.name,
        url: item.url,
      })),
    });
  };

  handleChange = (selectedApi) => {
    this.props.history.push(selectedApi.value);
  };

  render() {
    return (
      <div>
        <div className="search-wrapper">
          <div>
            <img src={Logo} alt="Redoc" />
            <SelectModule autoFocus="true" onChange={this.handleModuleChange} />
            <SelectApi
              availableApis={this.state.availableApisForService}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }
}

export default SelectApiPage;
