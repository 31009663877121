import React, { Component } from "react";
import Select from "react-select";

import { THEME_COLOR } from "../constants";

class SelectApi extends Component {
  render() {
    return (
      <>
        <Select
          className="select__api"
          autoFocus={this.props.autoFocus}
          value={this.props.value}
          onChange={this.props.onChange}
          options={this.props.availableApis}
          noOptionsMessage={() => "Nenhuma api encontrada..."}
          placeholder={"Selecione um serviço..."}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: THEME_COLOR,
            },
          })}
        />
      </>
    );
  }
}

export default SelectApi;
