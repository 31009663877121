import React, { Component } from "react";
import { Link } from "react-router-dom";
import { RedocStandalone } from "redoc";
import slugify from "slugify";

import SelectApi from "../SelectApi/SelectApi";
import SelectModule from "../SelectModule/SelectModule";
import { SERVICES, THEME_COLOR } from "../constants";

import "./RedocPage.css";
import Logo from "../logo-orbit.png";

class RedocPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedModule: SERVICES.find(
        (item) =>
          slugify(item.name).toLowerCase() === this.props.match.params.api
      ).module,
      availableApis: SERVICES.map((item) => ({
        value: slugify(item.name).toLowerCase(),
        label: item.name,
        url: item.url,
      })),
      activeApi: {
        url: "",
      },
    };

    const activeApiFromQuery = this.state.availableApis.find(
      (element) => element.value === this.props.match.params.api
    );

    if (activeApiFromQuery) {
      this.state.activeApi = activeApiFromQuery;
    } else {
      this.props.history.push("/");
    }
  }

  handleChange = (selectedApi) => {
    this.setState({
      activeApi: selectedApi,
    });

    this.props.history.push(selectedApi.value);
  };

  handleModuleChange = (selectedModule) => {
    this.setState({
      selectedModule: selectedModule.value,
      availableApisForService: SERVICES.filter(
        (item) => item.module === selectedModule.value
      ).map((item) => ({
        value: slugify(item.name).toLowerCase(),
        label: item.name,
        url: item.url,
      })),
    });
  };

  render() {
    return (
      <div>
        <header className="RedocPage-header">
          <Link to={"/"}>
            <img src={Logo} alt="Redoc" />
          </Link>

          <div className="RedocApiChangeArea">
            <SelectModule autoFocus="true" onChange={this.handleModuleChange} />

            <SelectApi
              className="select"
              availableApis={SERVICES.filter(
                (item) => item.module === this.state.selectedModule
              ).map((item) => ({
                value: slugify(item.name).toLowerCase(),
                label: item.name,
                url: item.url,
              }))}
              value={this.state.activeApi}
              onChange={this.handleChange}
            />
          </div>
        </header>
        <section className="container__redoc">
          <RedocStandalone
            specUrl={this.state.activeApi.url}
            options={{
              nativeScrollbars: true,
              scrollYOffset: 60,
              theme: {
                colors: {
                  primary: { main: THEME_COLOR },
                },
              },
            }}
          />
        </section>
      </div>
    );
  }
}

export default RedocPage;
